<template>
  <div class="header-inside">
    <div class="header-left">
      <Logo
        :width="`24`"
        :longwidth="`141.73`"
      />
      <component
        :is="LanguageMenu"
        :fsize="`sm`"
        :flag="18"
      />
    </div>
    <div class="header-right">

      <!-- 로그아웃 상태 -->
      <template v-if="!user.login">
        <v-btn to="/auth/login" :title="$t('log-in')" variant="outlined" color="blue-grey-darken-4" fsize="sm" bg="5xs">
          <div class="box" gap="2xs" valign="center" color="7xl">
            <v-icon icon="mdi-account-circle-outline" fsize="lg" mgt="4xs" />
            {{ $t('log-in') }}
          </div>
        </v-btn>
        <v-btn to="/auth/join" :title="$t('sign-up')" variant="outlined" color="blue-grey-darken-4" fsize="sm" bg="5xs">
          <div class="box" gap="2xs" valign="center" color="7xl">
            <v-icon icon="mdi-plus" fsize="md" />
            {{ $t('sign-up') }}
          </div>
        </v-btn>
      </template>

      <!-- 로그인 상태 -->
      <template v-else>
        <NuxtLink :to="`/me`" class="box" gap="xs" valign="center">
          <v-avatar
            :image="user?.thumbnail"
            :color="globalVariables.userset.color"
            class="img-text"
          >
            <span fsize="sm">{{ user?.nickname?.slice(0, 1) }}</span>
          </v-avatar>
          <span>
            {{ user.nickname }}
          </span>
        </NuxtLink>
      </template>

      <!-- 메뉴 -->
      <component
        :is="VerticalMenu"
      />

    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
// import LanguageMenu from '~/component/element/language-menu.vue'
// import VerticalMenu from '~/component/element/vertical-menu.vue'

export default {
  setup () {

    // 글로벌 스토어
    const { user } = storeToRefs(useAuthStore())

    // 메모리 비우기
    function restore () {
      LanguageMenu.value = null
      VerticalMenu.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const LanguageMenu: any = shallowRef(null)
    const VerticalMenu: any = shallowRef(null)
    const loadComponent = async () => {
      const LanguageMenu_load = await import('~/component/element/language-menu.vue')
      const VerticalMenu_load = await import('~/component/element/vertical-menu.vue')
      LanguageMenu.value = LanguageMenu_load.default || LanguageMenu_load
      VerticalMenu.value = VerticalMenu_load.default || VerticalMenu_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      user,

      // 컴포넌트
      LanguageMenu,
      VerticalMenu
    }
  }
}
</script>